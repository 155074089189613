@import "./color.scss";


html,
html > body,
html > body > div.root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: auto;
    font: 14px "Century Gothic", Futura, sans-serif;
    background-color: $white;
}