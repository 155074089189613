@import "./global.scss";


header {
  width: 100vw;
  height: 40px;
  display: flex;
  background-color: $black;
  padding: 0 10px;
  box-sizing: border-box;

  h1 {
    color: $white;
    font-size: 20px;
    margin: auto 0;
  }

}