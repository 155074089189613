@import "./global.scss";


.game {
  margin: auto;
  margin-top: 30px;
  background-color: $blue;
  border-radius: 30px;
  width: clamp(400px, 50%, 600px);
  box-sizing: border-box;
  overflow: auto;
  padding: 20px;

  @media (max-width: 550px) {
    width: 100vw;
    border-radius: 0;
  }

  > h1 {
    box-sizing: border-box;
    color: $white;
    font-size: clamp(35px, 3.7vw, 50px);
    text-align: center;
    margin: 15px 0;
  }

  > .main {
    width: fit-content;
    margin: 0 auto;

    $gap: 8px;

    .item {
      text-align: center;
      padding: 5px;
      margin: $gap 0;
      box-sizing: border-box;
      background-color: $white;
      border-radius: 15px;
      width: 100%;
      font-size: 16px;
      color: $red;
      font-weight: 600;
    }

    .item,
    .square {
      border: solid 3px $black;
    }

    > .buttons {
      display: flex;
      gap: $gap;

      button {
        box-shadow: 0 6px #999;
        margin-bottom: 6px + $gap;
        
        &[disabled] {
          border: solid 3px gray;
          color: gray;
        }

        &:hover {
          &:not([disabled]) {
            background-color: lightgray;
          }
        }

        &:active {
          &:not([disabled]) {
            box-shadow: 0 2px #666;
            transform: translateY(4px);
          }
        }

      }
    }

    > .board {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 8px;

      > .square {
        border-radius: 10px;
        color: transparent;
        text-align: center;
        aspect-ratio: 1;
        width: clamp(80px, 8vw, 100px);
        color: $black;
        font-size: clamp(60px, 5vw, 75px);
        font-weight: 600;
      }
    }

  }
}